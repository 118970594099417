// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import lightlogo from '../images/logo.svg';
import darklogo from '../images/dark-logo.svg';
import { useDarkMode } from './DarkModeContext'; // Import the DarkModeContext
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';




const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { darkMode, toggleDarkMode } = useDarkMode(); // Access the dark mode state and toggle function from the context

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        // Close mobile menu when dark mode is toggled
        setMobileMenuOpen(false);

        // Toggle body class for light mode background color
        if (darkMode) {
            document.body.classList.remove('light-mode');
        } else {
            document.body.classList.add('light-mode');
        }

        // Clean up the class on component unmount
        return () => {
            document.body.classList.remove('light-mode');
        };
    }, [darkMode]);

    const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);

    const handleServicesMenuEnter = () => {
        setServicesDropdownOpen(true);
    };

    const handleServicesMenuLeave = () => {
        setServicesDropdownOpen(false);
    };

    const handleSubmenuEnter = () => {
        setServicesDropdownOpen(true);
    };

    const handleSubmenuLeave = () => {
        setServicesDropdownOpen(false);
    };

    return (
        <header>
            <nav className={`fixed top-0 w-full z-10 border-gray-200 px-4 lg:px-6 py-2.5 ${darkMode ? 'bg-matblack' : 'bg-matwhite'}`}>
                <div className={`flex flex-wrap justify-between items-center mx-auto max-w-screen-xl ${isMobileMenuOpen ? 'lg:justify-center' : ''} p-4 ${isMobileMenuOpen ? '' : 'rounded-full'} ${darkMode ? 'bg-darkheader' : 'bg-lightheader'}`}>
                    <Link to="/" className="flex items-center">
                        <img
                            src={darkMode ? darklogo : lightlogo}
                            className="mr-3 h-6 sm:h-9"
                            alt="JOIN AI"

                        />
                    </Link>
                    <div className="flex items-center md:order-2">
                        <button
                            onClick={toggleDarkMode}
                            className={`flex items-center text-gray-800 hover:bg-gray-200 font-medium rounded-lg text-base p-4 pr-3 py-2 lg:py-2.5 mr-2 ${darkMode ? 'hover:bg-matblack text-matwhite' : 'hover:bg-matblue text-matblack'
                                } focus:outline-none`}
                        >
                            <FontAwesomeIcon
                                icon={darkMode ? faSun : faMoon}
                                className={`mr-2 ${darkMode ? 'text-matyellow' : 'text-matblack'}`}
                            />
                        </button>

                        {/* <a
                            href="#"
                            className={`text-white font-normal rounded-full text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none`} style={{ background: 'linear-gradient(45deg, #0680CD, #55A3D5)', color: '#ffffff' }}
                        >
                            Login
                        </a> */}
                        <button
                            onClick={toggleMobileMenu}
                            data-collapse-toggle="mobile-menu"
                            type="button"
                            className={`inline-flex items-center p-2 ml-1 text-sm text-matwhite rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 ${darkMode ? 'dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600' : ''}`}
                            aria-controls="mobile-menu"
                            aria-expanded={isMobileMenuOpen}
                        >

                            <span className="sr-only">Open main menu</span>
                            <span className="sr-only">Open main menu</span>
                            <svg className={`w-6 h-6 ${darkMode ? 'text-matblue' : 'text-matblue'} ${isMobileMenuOpen ? 'hidden' : 'block'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                            </svg>
                            <svg className={`w-6 h-6 ${darkMode ? 'text-matblue' : 'text-matblue'} ${isMobileMenuOpen ? 'block' : 'hidden'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                    <div className={`md:flex md:w-auto w-full md:order-1 ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
                        <ul className={`flex flex-col mt-4 text-sm font-light md:flex-row lg:space-x-8 md:space-x-2 md:mt-0`}>
                            <li>
                                <Link
                                    to="/"
                                    className={`block py-2 pr-4 pl-3 md:border-b-0 border-b-2 ${darkMode ? 'text-matwhite border-b-darkBorder' : 'text-matblack border-b-lightBorder'} lg:p-0 `}
                                    aria-current="page"
                                >
                                    Home
                                </Link>
                            </li>
                            <li
                                className="relative group self-center max-sm:w-full"
                                onMouseEnter={handleServicesMenuEnter}
                                onMouseLeave={handleServicesMenuLeave}
                            >
                                <Link to="/ai-services"> {/* Wrap the button with Link component */}
                                    <button
                                        className={`flex max-sm:w-full items-center justify-center py-2 pr-4 pl-3 hover:text-matblue lg:p-0 md:border-b-0 border-b-2 ${darkMode ? 'text-matwhite border-b-darkBorder' : 'text-matblack border-b-lightBorder'}`}
                                    >
                                        AI Services<svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                        </svg>
                                    </button>
                                </Link>

                                {isServicesDropdownOpen && (
                                    <div
                                        className={`absolute w-full md:w-40 pt-2 bg-white text-center md:text-left px-2 ${darkMode ? 'bg-darkheader' : 'bg-lightheader'} rounded-lg shadow-lg overflow-hidden z-10 transition-opacity duration-300 opacity-100 group-hover:opacity-100`}
                                        onMouseEnter={handleSubmenuEnter}
                                        onMouseLeave={handleSubmenuLeave}
                                    >
                                        <Link
                                            to="/ai-services/chest-imaging-ai"
                                            className={`block px-1 py-2 my-2 hover:text-matblue ${darkMode ? 'text-matwhite' : 'text-matblack'}`}
                                        >
                                            Chest Imaging AI
                                        </Link>
                                        <Link
                                            to="/ai-services/breast-imaging-ai"
                                            className={`block px-1 py-2 my-2 hover:text-matblue ${darkMode ? 'text-matwhite' : 'text-matblack'}`}
                                        >
                                            Breast Imaging AI
                                        </Link>
                                        <Link
                                            to="/ai-services/stroke-ai"
                                            className={`block px-1 py-2 my-2 hover:text-matblue ${darkMode ? 'text-matwhite' : 'text-matblack'}`}
                                        >
                                            Stroke AI
                                        </Link>
                                        <Link
                                            to="/ai-services/endoscopy-ai"
                                            className={`block px-1 py-2 my-2 hover:text-matblue ${darkMode ? 'text-matwhite' : 'text-matblack'}`}
                                        >
                                            Endoscopy AI
                                        </Link>
                                    </div>
                                )}
                            </li>

                            <li>
                                <Link
                                    to="/blog"
                                    className={`block py-2 pr-4 pl-3 md:border-b-0 border-b-2 hover:text-matblue lg:p-0 ${darkMode ? 'text-matwhite border-b-darkBorder' : 'text-matblack border-b-lightBorder'}`}
                                >
                                    Blog
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact"
                                    className={`block py-2 pr-4 pl-3 md:border-b-0 border-b-2 hover:text-matblue lg:p-0 ${darkMode ? 'text-matwhite border-b-darkBorder' : 'text-matblack border-b-lightBorder'}`}
                                >
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
