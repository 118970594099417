// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './page/Home';
import Blog from './components/Blog';
import SinglePost from './components/SinglePost';
import ContactPage from './page/ContactPage';
import Header from './components/Header';
import './App.css'; // Import the CSS file
import Footer from './components/Footer';
import { Helmet } from "react-helmet";
import Chestservice from './page/ChestService';
import BreastService from './page/BreastService';
import StrokeService from './page/StrokeService';
import EndoscopyService from './page/EndoscopyService';
import ScrollToTop from './components/ScrollToTop';
import CategoryPage from './components/CategoryPage';
import TagPage from './components/TagPage';
import Lunit from './components/services/sub/Lunit';
import Inspectra from './components/services/sub/Inspectra';
import Siriraj from './components/services/sub/Siriraj';
import Services from './page/Services';
import FreeTrial from './page/FreeTrial';
import NotFound from './page/NotFound';
import AuthorProfile from './components/AuthorProfile';
import CookiePolicy from './page/CookiePolicy';
import PrivacyPolicy from './page/PrivacyPolicy';
import Clarity from './Clarity';




function App() {
  return (
    <Router>
      <Helmet>
      <title>Join AI - Radiology AI Platform As a Service | Medical AI solutions</title>
        <meta name="description" content="Join AI is pioneering the integration of artificial intelligence in the realms of radiology and endoscopy, transforming diagnostic precision and patient care." />
      </Helmet>
      <Clarity />
      <div className="App min-h-screen">
        <Header/>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<SinglePost />} />
          <Route path="/author/:authorName" element={<AuthorProfile />} />
          <Route path="/category/:slug" element={<CategoryPage />} />
          <Route path="/tag/:slug" element={<TagPage />} />
          <Route path="/ai-services" element={<Services />} />
          <Route path="/ai-services/chest-imaging-ai" element={<Chestservice />} />
          <Route path="/ai-services/chest-imaging-ai/lunit-insight-cxr" element={<Lunit />} />
          <Route path="/ai-services/chest-imaging-ai/inspectra-by-perceptra" element={<Inspectra />} />
          <Route path="/ai-services/chest-imaging-ai/siriraj-ai" element={<Siriraj />} />
          <Route path="/ai-services/breast-imaging-ai" element={<BreastService />} />
          <Route path="/ai-services/stroke-ai" element={<StrokeService />} />
          <Route path="/ai-services/endoscopy-ai" element={<EndoscopyService />} />
          <Route path="/contact" element={<ContactPage/>} />
          {/* <Route path="/free-trial" element={<FreeTrial/>} /> */}
          <Route path="*" element={<NotFound />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
