import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2'; // Import the phone input component
import 'react-phone-input-2/lib/style.css'; // Import default styles
import emailjs from 'emailjs-com';
import { useDarkMode } from './DarkModeContext'; // Import the DarkModeContext
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome component
import { faSpinner } from '@fortawesome/free-solid-svg-icons'; // Import spinner icon

const ContactForm = () => {
  const { darkMode } = useDarkMode();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
    chooseOption: '',
  });

  const [metadata, setMetadata] = useState({});
  const [countryCode, setCountryCode] = useState(''); // For dynamically setting country code
  const [loading, setLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Fetch user metadata (IP, location, ISP, etc.)
  useEffect(() => {
    fetch('https://ipinfo.io/json?token=36a84f0265f19f') // Replace with your API token
      .then((response) => response.json())
      .then((data) => {
        setMetadata({
          ip: data.ip,
          city: data.city,
          region: data.region,
          country: data.country,
          isp: data.org, // ISP Name
        });

        // Dynamically set the country code based on the user's location
        setCountryCode(data.country?.toLowerCase()); // Country code must be in lowercase for react-phone-input-2
      })
      .catch((error) => console.error('Error fetching metadata:', error));
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value, // Update phone number including country code
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    // Validate reCAPTCHA
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LeKFdIpAAAAAHClw2SBBMoBtqvxAfoIK0vmt0js', { action: 'submit' })
        .then((token) => {
          const formDataWithMetadata = {
            ...formData,
            metadata, // Include metadata in the payload
          };

          emailjs.send('service_9gczifq', 'template_bmcj8vf', {
            ...formDataWithMetadata,
            'g-recaptcha-response': token,
          }, 'WIn8AFCPcYvVPIgth')
            .then((response) => {
              console.log('Form successfully submitted with metadata:', response);
              setSuccessMessage('Thank you for your interest, Our team will get back to you.');
              setErrorMessage(null);
              setFormData({
                fullName: '',
                email: '',
                phone: '',
                message: '',
                chooseOption: '',
              });
              setLoading(false); // Set loading to false
            })
            .catch((error) => {
              console.error('Email sending failed:', error);
              setErrorMessage('Error sending message. Please try again later.');
              setSuccessMessage(null);
              setLoading(false); // Set loading to false
            });
        });
    });
  };

  return (
    <form className="max-w-md mt-8" onSubmit={handleSubmit}>
      {/* Full Name */}
      <div className="mb-4">
        <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="fullName">
          Full Name
        </label>
        <input
          className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
          id="fullName"
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
      </div>

      {/* Email */}
      <div className="mb-4">
        <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="email">
          Email
        </label>
        <input
          className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
          id="email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      {/* Phone Number with Country Code */}
      <div className="mb-4">
        <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="phone">
          Phone
        </label>
        <PhoneInput
          country={countryCode || 'us'} // Default to 'us' if countryCode is not set
          value={formData.phone}
          onChange={handlePhoneChange}
          inputStyle={{
            width: '100%',
            borderRadius: '0.25rem',
            borderColor: darkMode ? '#fff' : '#181818',
            backgroundColor: darkMode ? '#181818' : '#fff',
            color: darkMode ? '#fff' : '#181818',
          }}
        />
      </div>

      {/* Interested In */}
      <div className="mb-4">
        <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="chooseOption">
          Interested in
        </label>
        <div className="relative">
          <select
            className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
            id="chooseOption"
            name="chooseOption"
            value={formData.chooseOption}
            onChange={handleChange}
            required
          >
            <option value="">Choose one...</option>
            <option value="Chest Imaging AI">Chest Imaging AI</option>
            <option value="Breast Imaging AI">Breast Imaging AI</option>
            <option value="Stroke AI">Stroke AI</option>
            <option value="Endoscopy AI">Endoscopy AI</option>
          </select>
        </div>
      </div>

      {/* Message */}
      <div className="mb-4">
        <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="message">
          Message
        </label>
        <textarea
          className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="4"
          required
        ></textarea>
      </div>

      {/* Submit Button */}
      <div className="flex items-center justify-end">
        <button
          className={`bg-matblue hover:bg-blue-700 text-matwhite font-normal py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:shadow-[0_20px_20px_-5px_rgb(33,150,243,0.20)] flex items-center`}
          type="submit"
          disabled={loading} // Disable button while loading
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> // Loader icon with spin animation
          ) : 'Submit'}
        </button>
      </div>

      {successMessage && (
        <div className="p-2 mb-4 text-matblue text-right">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="p-2 mb-4 text-formError text-right">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

export default ContactForm;
